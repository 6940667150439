/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : FrontEnd Team  
==============================================================================================*/

/* import bourbon - http://bourbon.io/docs */
@import "bourbon/bourbon";

@mixin transition($attr){
    transition: $attr;
    -webkit-transition: $attr;
    -moz-transition: $attr;
    -ms-transition: $attr;
    -o-transition: $attr;
}
@mixin transform($attr){
    transform: $attr;
    -webkit-transform: $attr;
    -moz-transform: $attr;
    -ms-transform: $attr;
    -o-transform: $attr;
}
@mixin borderRadius($attr){
    border-radius: $attr;
    -webkit-border-radius: $attr;
    -moz-border-radius: $attr;
    -ms-border-radius: $attr;
    -o-border-radius: $attr;
}
@mixin boxShadow($attr){
    box-shadow:  $attr;
    -webkit-box-shadow: $attr;
    -moz-box-shadow: $attr;
    -ms-box-shadow: $attr;
    -o-box-shadow: $attr
}
@mixin boxSizing($attr){
    box-sizing:  $attr;
    -webkit-box-sizing: $attr;
    -moz-box-sizing: $attr;
    -ms-box-sizing: $attr;
    -o-box-sizing: $attr
}
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
}
@mixin boxSizing($attr){
    -webkit-box-sizing: $attr;
    -moz-box-sizing: $attr;
    box-sizing: $attr;
}
@mixin afterboth{
    &:after{content:"";clear: both;display: block;}
}

$f_work: 'Work Sans', sans-serif;

/* color global
----------------------------------------------------------------------------------------------*/
$c_111: #111;
$c_333: #333;
$c_666: #666;
$c_999: #999;
$c_ea: #eaeaea;
$c_ckb: #135f45;
$c_ckb2: #129b6c;
$c_yllow: #f9cf54;
$c_yllow2: #e7cc1c;

$c_ind_red: #cb2525;
$c_ind_yl: #e5880f;
$c_ind_gr: #17ae65;
$c_ind_bl: #1d45d6;
$c_ind_ung: #5b42da;
$c_ind_bl2: #0f83e5;
$c_ind_ab: #9f9f9f;