/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

body {
  color: #333;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  position: relative;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
}
figure {
  margin: 0;
  padding: 0;
  img {
    vertical-align: middle;
  }
}
img {
  border: 0;
  max-width: 100%;
  height: auto;
}
p {
  margin: 1px 0 15px;
  line-height: 20px;
}
input,
textarea,
select {
  font-size: 12px;
  border: none;
  border: 1px solid #d8d8d8;
  padding: 10px;
  margin: 0;
  color: #666;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
input[type="checkbox"],
input[type="radio"] {
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}

/* global
----------------------------------------------------------------------------------------------*/
.after_clear:after,
.wrapper:after,
section:after,
.container:after {
  clear: both;
  display: block;
  content: " ";
}
.before_clear:before {
  clear: both;
  display: block;
  content: " ";
}

/* structure 
----------------------------------------------------------------------------------------------*/
section {
  position: relative;
}
.wrapper {
  width: 1170px;
  margin: 0 auto;
}
.container {
  width: 1600px;
  margin: 0 auto;
  position: relative;
  display: block;
}

.btn_box {
  position: relative;
  overflow: hidden;
  background: $c_yllow;
  @include transition(all ease-out 0.2s);
  display: inline-block;
  span {
    padding: 0 40px;
    font-weight: 900;
    font-size: 16px;
    color: #fff;
    line-height: 50px;
    display: inline-block;
    text-transform: uppercase;
  }
  &:after {
    font-weight: 900;
    font-size: 16px;
    color: #fff;
    line-height: 50px;
    content: attr(data-text);
    @include boxSizing(border-box);
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    text-transform: uppercase;
    @include transform(translate3d(0, 25%, 0));
    opacity: 0;
    @include transition(all ease-out 0.2s);
    top: 0;
  }
  &:hover {
    @include transition(all ease-out 0.2s);
    background: $c_ckb;
    span {
      @include transform(translate3d(0, -25%, 0));
      @include transition(all ease-out 0.25s);
      opacity: 0;
    }
    &:after {
      @include transform(translate3d(0, 0, 0));
      opacity: 1;
      @include transition(all ease-out 0.25s);
    }
  }
  &.list_arr {
    background: transparent;
    border: 2px solid $c_ckb;
    span {
      line-height: 46px;
      color: $c_ckb;
      padding: 0 40px 0 20px;
    }
    &:after {
      line-height: 46px;
      padding: 0 40px 0 20px;
      text-align: left;
    }
    &:before {
      width: 10px;
      height: 15px;
      background: url("../images/material/arr_btn.png") no-repeat top center;
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 18px;
      margin: auto;
      content: "";
      @include transition(all ease-out 0.15s);
    }
    &:hover {
      background: $c_ckb;
      &:before {
        background-position: bottom center !important;
        @include transition(all ease-out 0.25s);
      }
    }
    &.more {
      span {
        padding: 0 30px 0 60px;
      }
      &:before {
        width: 28px;
        height: 28px;
        background: url("../images/material/more.png") no-repeat top center;
        right: auto;
        left: 15px;
      }
      &:after {
        padding: 0 30px 0 60px;
      }
      &:hover {
        &:before {
          @include transform(rotate(180deg));
          @include transition(all ease-out 0.5s);
        }
      }
    }
    &.yellow {
      background: $c_yllow;
      border-color: $c_yllow;
      &:before {
        background: url("../images/material/arr_btn-2.png") no-repeat top center;
      }
      span {
        color: $c_333;
      }
      &:hover {
        background: $c_ckb;
        border-color: $c_ckb;
      }
    }
    &.no-arr {
      &:before {
        display: none;
      }
      span {
        padding: 0 20px;
      }
      &:after {
        padding: 0 20px;
      }
    }
  }
}
.box-button-career {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    margin-bottom: 10px;
  }
}
.ic_box {
  font-weight: 800;
  position: relative;
  color: $c_ckb;
  font-size: 48px;
  line-height: 55px;
  &:after {
    background: url("../images/material/ico_box-box.png") no-repeat bottom
      center;
    width: 55px;
    height: 10px;
    content: "";
    position: absolute;
  }
  &.btm {
    padding-bottom: 20px;
    &:after {
      background-position: top center;
      left: 0;
      bottom: 0;
    }
  }
  &.top {
    padding-top: 25px;
    &:after {
      left: 0;
      right: auto;
      top: 0;
    }
  }
  &.topCent {
    padding-top: 30px;
    &:after {
      left: 0;
      right: 0;
      margin: auto;
      bottom: auto;
      top: 0;
    }
  }
  &.little {
    padding-top: 20px;
    font-size: 23px;
    &:after {
      background: url("../images/material/ico_box_little.png") no-repeat center;
      width: 42px;
      left: 0;
      top: 0;
    }
  }
}

.continue {
  //color: #129b6c;
  color: $c_333;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  @include transition(color ease-out 0.2s);
  span {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: top;
    margin-left: 8px;
    position: relative;
    top: 1px;
    background: url("../images/material/arr_btn_box.png") no-repeat center;
    @include transition(all ease-out 0.2s);
  }
  &:hover {
    @include transition(color ease-out 0.2s);
    color: $c_ckb;
    span {
      @include transition(all ease-out 0.3s);
      margin-left: 12px;
    }
  }
  &.ico-2 {
    line-height: 24px;
    span {
      background: #79d68f url("../images/material/arr_btn_box_2.svg") repeat-x
        left center;
      width: 24px;
      height: 24px;
      margin: 0;
    }
    &:hover {
      span {
        background-position: left 24px center;
        @include transition(all ease-out 0.3s);
      }
    }
  }
}
.read {
  display: inline-block;
  text-transform: uppercase;
  color: #129b6c;
  font-size: 16px;
  font-weight: 800;
  @include transition(color ease-out 0.25s);
  &:hover {
    @include transition(all ease-out 0.25s);
    color: $c_ckb;
  }
}

.loaderhome {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  overflow: hidden;
  .wrap_loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background: #fff;
  }
  .logobg {
    width: 210px;
    height: 70px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    bottom: 0;
    background: url("../images/material/logo.png") no-repeat;
    background-size: 100%;
    opacity: 1;
  }
}

/* header
----------------------------------------------------------------------------------------------*/
header {
  padding: 19px 0;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9;
  top: 0;
  background: #fff;
  @include transition(all 0.3s ease-out);
  .wrapper {
    width: 1660px;
    z-index: 2;
    position: relative;
  }
  .logo {
    float: left;
  }
  .main_head {
    float: right;
    margin-top: 6px;
  }
  .nav {
    float: left;
    .top_mobile {
      display: none;
    }
    ul {
      @extend .after_clear;
      li {
        float: left;
        margin-right: 45px;
        a {
          line-height: 50px;
          font-size: 16px;
          color: $c_333;
          text-transform: uppercase;
          font-weight: 600;
          @include transition(color ease-out 0.25s);
          &:hover,
          &.active {
            color: #135f45;
            @include transition(color ease-out 0.25s);
          }
        }
        ul {
          display: none;
        }
      }
    }
  }
  .lang {
    float: left;
    padding: 0 15px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    .act_lang {
      line-height: 50px;
      font-weight: 500;
      font-size: 16px;
      color: $c_999;
      cursor: pointer;
    }
    .drop {
      display: none;
      position: absolute;
      z-index: 2;
      top: 90%;
      padding-left: 3px;
      a {
        font-size: 16px;
        color: $c_333;
        top: 100%;
        font-weight: 500;
        &:hover {
          color: $c_ckb;
          @include transition(color ease-out 0.2s);
        }
      }
    }
  }
  .src {
    float: left;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    .btn_src {
      width: 14px;
      padding: 0 15px;
      height: 50px;
      display: inline-block;
      position: relative;
      background: url("../images/material/ico_src_head.png") no-repeat center;
      cursor: pointer;
    }
    .drop_src {
      position: fixed;
      background: #fff;
      left: 0;
      right: 0;
      height: 100px;
      top: 0;
      z-index: 2;
      border-bottom: 1px solid #eaeaea;
      display: none;
      form {
        padding: 26px 50px;
        position: relative;
        input[type="text"] {
          width: 100%;
          @include boxSizing(border-box);
          font-size: 20px;
          border: none;
          font-style: italic;
          padding: 10px 50px;
        }
        input[type="submit"] {
          position: absolute;
          left: 40px;
          top: 0;
          bottom: 0;
          width: 45px;
          height: 45px;
          padding: 0;
          display: inline-block;
          background: url("../images/material/ico_src_head.png") no-repeat
            center;
          border: none;
          margin: auto;
          cursor: pointer;
        }
        .close {
          display: inline-block;
          width: 45px;
          height: 45px;
          position: absolute;
          right: 40px;
          top: 0;
          bottom: 0;
          margin: auto;
          background: url("../images/material/close_src.png") no-repeat center;
          cursor: pointer;
        }
      }
    }
  }
  .inquery {
    float: left;
    margin-left: 5px;
  }
  .bg_white {
    position: absolute;
    height: 100px;
    left: 0;
    right: 0;
    top: 0;
    background: #fff;
    z-index: 1;
    top: -100px;
  }
  &.home {
    // background: transparent;
    // padding: 30px 0;
    border-bottom: 0;
    &:after {
      opacity: 0;
    }
    &.float {
      &:after {
        opacity: 1;
        -webkit-transition-delay: 0.3s; /* Safari */
        transition-delay: 0.3s;
        -moz-transition-delay: 0.3s;
      }
    }
  }
  &.float {
    padding: 19px 0;
    @include transition(all 0.3s ease-out);
    background: #fff;
  }
  &:after {
    content: "";
    height: 1px;
    background: #eaeaea;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  .burger {
    display: none;
    position: relative;
    z-index: 99999;
  }
  &.scroll-down {
    @include transition(all 0.3s ease-out);
    top: -100px;
  }
}
.dte {
  display: block;
  font-size: 11px;
}

/* homepage
----------------------------------------------------------------------------------------------*/
.sliderHome {
  position: relative;
  padding: 100px 0 0;
  &:after {
    content: "";
    position: absolute;
    height: 4px;
    top: auto;
    left: 0;
    right: 0;
    background: #f8cf53;
    bottom: 0;
    width: 60%;
  }
  .slider {
    overflow: hidden;
    // .slide {
    //   &.video {
    //     height: calc(100vw * 0.33);
    //     display: flex;
    //     align-items: center;
    //     background: #000;
    //   }
    // }
    .images {
      position: relative;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.6;
        background: linear-gradient(
          180deg,
          rgba(14, 97, 81, 0) 0%,
          #0e6151 100%
        );
        height: 30%;
        z-index: 2;
        display: block;
      }
      img {
        display: block;
        width: 100%;
        height: calc(100svh - 100px);
        object-fit: cover;
      }
      video {
        height: calc(100svh - 100px);
        width: 100%;
        object-fit: cover;
        display: block;
        // transform: scale(0.6);
      }
    }
    .caption {
      width: 1170px;
      padding-left: 78px;
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      left: 0;
      right: 0;
      margin: -60px auto 0;
      @include boxSizing(border-box);
      h1,
      h2 {
        font-size: 36px;
        font-weight: 800;
        color: $c_ckb;
        width: 565px;
        line-height: 120%;
        margin-bottom: 35px;
      }
    }
    .slick-arrow {
      position: absolute;
      z-index: 3;
      width: 60px;
      height: 60px;
      top: 0;
      bottom: 0;
      background: url("../images/material/arr_slide-2.svg") no-repeat center;
      padding: 0;
      border: none;
      font-size: 0;
      margin: auto;
      right: calc(((100vw - 1170px) / 2) - 80px);
      left: auto;
      cursor: pointer;
      transition: all ease-out 0.25s;
      &:hover {
        opacity: 0.8;
        transition: all ease-out 0.25s;
      }
      &.slick-prev {
        left: calc(((100vw - 1170px) / 2) - 80px);
        right: auto;
        transform: rotate(180deg);
      }
    }
  }
  .ckb-group-stay {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: auto;
    bottom: 30px;
    .wrapper {
      background: linear-gradient(180deg, #0e6151 0%, #197967 100%);
      box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.1);
      padding: 24px 70px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      &:after {
        display: none;
      }
      &:before {
        content: "";
        background: url("../images/material/bg-flag.svg") no-repeat left top 6px;
        position: absolute;
        top: 0;
        left: 0;
        width: 116px;
        height: 100%;
        background-size: contain;
      }
      h3 {
        color: #fff;
        font-size: 21px;
        font-weight: 900;
        text-transform: uppercase;
        margin: 0;
      }
      .link-icon {
        display: flex;
        justify-content: flex-end;
        a {
          display: inline-flex;
          align-items: center;
          color: #fff;
          font-size: 15px;
          text-transform: uppercase;
          font-weight: 700;
          padding-right: 40px;
          margin-right: 40px;
          border-right: 1px solid rgba(255, 255, 255, 0.3);
          transition: all ease-out 0.25s;
          &:hover {
            color: #79d68f;
            transition: all ease-out 0.25s;
          }
          img {
            display: block;
            width: 32px;
            margin-right: 8px;
          }
          &:last-child {
            margin-right: 0;
            padding-right: 0;
            border-right: 0;
          }
        }
      }
    }
  }
}

.nav_slideHome {
  position: absolute;
  z-index: 4;
  top: 45%;
  @include transform(translateY(-45%));
  left: 80px;
  right: auto;
  margin: auto;
  .slick-arrow {
    position: relative;
    z-index: 2;
    margin: auto;
    width: 50px;
    height: 50px;
    padding: 0;
    font-size: 0;
    border: 1px solid #006766;
    display: block;
    @include borderRadius(50%);
    background: none;
    @include transition(opacity ease-out 0.25s);
    cursor: pointer;
    margin: 40px 0;
    &:after {
      width: 13px;
      height: 16px;
      background: url("../images/material/arr_round_slide.png") no-repeat center
        center;
      position: absolute;
      content: "";
      display: block;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &.slick-prev {
      margin-top: 0;
      &:after {
        @include transform(rotate(180deg));
      }
    }
    &.slick-next {
      margin-bottom: 0;
    }
    &:hover {
      opacity: 1;
      @include transition(opacity ease-out 0.25s);
    }
  }
  .line {
    height: 2px;
    background: #359675;
    display: block;
    width: 200px;
    max-width: 200px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.higlight {
  background: #13523c;
  height: 140px;
  position: relative;
  &:before {
    width: 50%;
    height: 100%;
    content: "";
    position: absolute;
    left: auto;
    background: #167756;
    top: 0;
    display: block;
    right: 0;
    z-index: 1;
  }
  .left {
    @extend .after_clear;
    width: 63.5%;
    padding: 30px 90px 30px 0;
    @include boxSizing(border-box);
    position: relative;
    z-index: 2;
    background: #13523c;
  }
  .title_ico {
    float: left;
    @extend .after_clear;
    img {
      float: left;
    }
    .ic_box {
      display: block;
      margin-left: 90px;
      width: 155px;
      line-height: 30px;
      font-size: 28px;
      font-weight: 900;
      color: #fff;
    }
  }
  .slideNews {
    margin-left: 285px;
    margin-top: 5px;
    .news {
      a {
        color: #fff;
        .dte {
          color: #52c69e;
          margin-bottom: 6px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          margin: 0;
          height: 48px;
          overflow: hidden;
        }
      }
    }
  }
  .right {
    float: right;
    width: 36.5%;
    @include boxSizing(border-box);
    padding: 45px 0 45px 50px;
    z-index: 2;
    position: relative;
    background: #167756;
    position: relative;
    a {
      display: inline-block;
      line-height: 50px;
      position: relative;
      width: 260px;
      text-align: center;
      color: $c_yllow;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 16px;
      @include boxSizing(border-box);
      @include transition(all ease-out 0.25s);
      i {
        display: inline-block;
        vertical-align: middle;
        margin-right: 14px;
      }
      &.track {
        background: #124936;
        i {
          width: 21px;
          height: 29px;
        }
        &:hover,
        &.act {
          background: $c_yllow;
          color: $c_ckb;
          @include transition(all ease-out 0.25s);
          i {
            background-position: bottom center !important;
          }
        }
      }
      &.booking {
        border: 2px solid $c_yllow;
        line-height: 46px;
        margin-left: 10px;
        i {
          width: 24px;
          height: 24px;
        }
        &:hover {
          background: $c_yllow;
          color: $c_ckb;
          @include transition(all ease-out 0.25s);
          i {
            background-position: bottom center !important;
          }
        }
      }
    }
    .drop_ship {
      position: absolute;
      display: block;
      background: #124936;
      width: 230px;
      display: none;
      padding: 15px;
      top: 110px;
      &:before {
        width: 0;
        border-bottom: 10px solid #124936;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: "";
        display: block;
        position: absolute;
        left: 20px;
        right: auto;
        top: -9px;
      }
      form {
        position: relative;
        display: block;
        input[type="text"] {
          width: 100%;
          @include boxSizing(border-box);
          border: none;
          font-size: 16px;
          padding: 8px 50px 8px 10px;
        }
        input[type="submit"] {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          background: $c_yllow;
          color: $c_ckb;
          font-weight: 600;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}
.homeAbout {
  position: relative;
  overflow: hidden;
  padding: 80px 0;
  .img_about {
    position: relative;
    img {
      display: block;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
  }
  .videos {
    position: relative;
    width: 550px;
    .wrap_video {
      position: absolute;
      z-index: 2;
      left: 0;
      right: -1px;
      top: 0;
      bottom: 0;
      height: 360px;
      > img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background: #146d5b;
        opacity: 0.4;
      }
      .play_vid {
        width: 120px;
        height: 120px;
        position: absolute;
        @include borderRadius(50%);
        background: rgba(255, 255, 255, 0.6)
          url("../images/material/ico_play_vids.png") no-repeat center;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        z-index: 2;
        cursor: pointer;
        @include transition(all ease-out 0.25s);

        &:hover {
          @include transition(all ease-out 0.25s);
          @include transform(scale(0.9));
          background-color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    #vidAbout {
      height: 360px;
      width: 100%;
    }
  }
  .txt_about {
    position: relative;
    .rh_text {
      float: none;
      width: calc(100% - 550px);
      padding-left: 84px;
      h1,
      h2 {
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 25px;
        text-transform: uppercase;
        padding-top: 0;
        &:after {
          display: none;
        }
      }
      p {
        font-size: 18px;
        line-height: 160%;
        margin-bottom: 24px;
      }
      .lines {
        position: relative;
        height: 2px;
        background: $c_ckb;
        display: block;
        margin: 30px 0 40px;
        max-width: 270px;
        left: -185px;
        width: 100%;
      }
      .videos {
        position: relative;
        margin-bottom: 30px;
        .wrap_video {
          position: absolute;
          z-index: 2;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          height: 335px;
          .play_vid {
            width: 120px;
            height: 120px;
            position: absolute;
            @include borderRadius(50%);
            background: rgba(255, 255, 255, 0.6)
              url("../images/material/ico_play_vids.png") no-repeat center;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            z-index: 2;
            cursor: pointer;
            @include transition(all ease-out 0.25s);
            &:hover {
              @include transition(all ease-out 0.25s);
              @include transform(scale(0.9));
              background-color: rgba(255, 255, 255, 0.8);
            }
          }
        }
        #vidAbout {
          height: 335px;
          width: 100%;
        }
      }
    }
  }
}

.businessHome {
  position: relative;
  @include boxSizing(border-box);
  .wrapper {
    background: url("../images/content/bg_business_home.jpg") no-repeat top
      center;
    background-size: cover;
    padding: 72px 68px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    &:before {
      content: "";
      width: 116px;
      height: 90px;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      background: url("../images/material/bg-flag.svg") no-repeat center;
      background-size: 100%;
    }
  }
  .top_text {
    width: 400px;
    margin: 0 auto;
    text-align: left;
    h6 {
      font-size: 19px;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 12px;
      color: #fff;
    }
    h1,
    h2 {
      font-size: 28px;
      font-weight: 900;
      color: #fff;
      padding-top: 0;
      line-height: 120%;
      color: #79d68f;
      margin-bottom: 20px;
      &:after {
        display: none;
      }
    }
    p {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .list-services {
    width: calc(100% - 400px);
    padding-left: 40px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    .item-services {
      width: calc(50% - 32px);
      margin-right: 64px;
      padding: 22px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      &:nth-child(2n) {
        margin-right: 0;
      }
      &:nth-last-child(2),
      &:last-child {
        border-bottom: none;
      }
      a {
        color: #fff;
        text-transform: capitalize;
        position: relative;
        display: block;
        &:hover {
          color: #79d68f;
        }
        span {
          position: absolute;
          left: auto;
          margin: auto;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
    }
  }
  .ListBusiness {
    margin: 40px -15px 0;
    .item {
      float: left;
      width: 25%;
      position: relative;
      cursor: pointer;
      .img {
        img {
          display: block;
          width: 100%;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.6);
        }
      }
      .capt {
        display: block;
        position: absolute;
        top: 50%;
        right: 30px;
        left: 30px;
        margin: auto;
        text-align: center;
        @include transform(translateY(-50%));
        h5 {
          color: #fff;
          font-weight: 800;
          font-size: 19px;
          text-transform: uppercase;
          line-height: 22px;
          margin-bottom: 12px;
          height: 44px;
        }
        a {
          color: $c_yllow2;
          font-weight: 800;
          text-transform: uppercase;
        }
      }
    }
  }
}

.top_text {
  width: 770px;
  margin: 0 auto;
  text-align: center;
  h1 {
    font-size: 48px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 30px;
  }
  p {
    font-size: 19px;
    line-height: 30px;
  }
}

.home-global-player {
  position: relative;
  padding: 100px 0;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
  }
  .img-global {
    width: 550px;
    box-sizing: border-box;
    padding: 0;
    position: relative;
    &:before {
      content: "";
      width: 240px;
      top: 0;
      bottom: 0;
      left: auto;
      right: 0;
      background: var(
        --linear,
        linear-gradient(180deg, #37aa8d 0%, #319f84 15.1%, #0f634e 100%)
      );
      position: absolute;
      z-index: -1;
      display: none;
    }
    figure {
      position: relative;
      img {
        display: block;
        width: 100%;
      }
      &:after {
        content: "";
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
          180deg,
          rgba(20, 109, 91, 0) 0%,
          #1d8f78 100%
        );
        position: absolute;
        height: 70%;
      }
    }
  }
  .desc-global {
    width: calc(100% - 550px);
    padding-right: 100px;
    box-sizing: border-box;
    h3 {
      font-size: 24px;
      font-weight: 900;
      text-transform: uppercase;
      color: #135f45;
      line-height: 120%;
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
      line-height: 160%;
      color: $c_333;
    }
    .ls-global {
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;
      &__item {
        width: 33%;
        padding: 0 20px;
        text-align: center;
        box-sizing: border-box;
        .count {
          font-size: 40px;
          line-height: 120%;
          color: #2eaf90;
          font-weight: 900;
          text-align: center;
          position: relative;
          padding-top: 4px;
          small {
            font-size: 19px;
            font-weight: 400;
            display: block;
            line-height: 1;
            color: $c_333;
            margin-top: 4px;
          }
        }
      }
    }
  }
}

.cus_say {
  padding: 50px 0 100px;
  .container {
    background: #eaeaea;
    position: relative;
    margin-top: 0;
    padding: 90px 0 75px;
  }
  h3 {
    font-size: 24px;
    line-height: 120% !important;
    margin-bottom: 40px;
    padding-top: 0 !important;
    text-transform: uppercase;
    &:after {
      display: none;
    }
  }
  .slider_customer {
    padding: 48px 164px 48px 72px;
    position: relative;
    background: url("../images/content/img-customer-bg.jpg") no-repeat center;
    background-size: cover;
    .slide {
      p {
        font-size: 16px;
        line-height: 160%;
        color: rgba(255, 255, 255, 0.7);
      }
      .ket {
        margin-top: 20px;
        p {
          display: inline-block;
          font-weight: 700;
          padding-right: 15px;
          margin-right: 15px;
          border-right: 2px solid #333;
          margin-bottom: 0;
          &:last-child {
            border: none;
            padding-right: 0;
            margin-right: 0;
          }
        }
      }
      .img-customer {
        position: relative;
        display: flex;
        margin-bottom: 24px;
        align-items: center;
        figure {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          align-self: flex-start;
          img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        figcaption {
          position: relative;
          width: calc(100% - 80px);
          box-sizing: border-box;
          padding-left: 24px;
          position: relative;
          padding-right: 200px;
          h4 {
            font-size: 20px;
            color: #79d68f;
            font-weight: 900;
            line-height: 120%;
            margin-bottom: 8px;
          }
          p {
            color: #fff;
            font-weight: 600;
            line-height: 160%;
            margin: 0;
            font-weight: 400;
          }
          .view-yt {
            position: absolute;
            color: #fff;
            display: inline-flex;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            transition: all ease-out 0.25s;
            &:hover {
              transition: all ease-out 0.25s;
              color: #f8cf53;
            }
            img {
              display: block;
              width: 32px;
              margin-right: 8px;
            }
          }
        }
      }
    }
    .slick-arrow {
      width: 60px;
      height: 60px;
      padding: 0;
      position: absolute;
      z-index: 3;
      font-size: 0;
      background: url("../images/material/arr_slide.svg") no-repeat center;
      border: none;
      top: 50%;
      transform: translateY(calc(-50% + 30px));
      left: auto;
      right: 70px;
      cursor: pointer;
      &.slick-prev {
        transform: rotate(180deg) translateY(calc(50% + 30px));
      }
    }
  }
  .nav_slideCus {
    position: absolute;
    right: 12%;
    top: 55%;
    @include transform(translateY(-50%));
    .slick-arrow {
      position: relative;
      z-index: 2;
      margin: auto;
      right: 0;
      width: 50px;
      height: 50px;
      padding: 0;
      font-size: 0;
      border: 1px solid #006766;
      @include borderRadius(50%);
      background: none;
      @include transition(opacity ease-out 0.25s);
      cursor: pointer;
      display: block;
      margin: 40px 0;
      &:after {
        width: 13px;
        height: 16px;
        background: url("../images/material/arr_round_slide.png") no-repeat
          center center;
        position: absolute;
        content: "";
        display: block;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &.slick-prev {
        margin-top: 0;
        &:after {
          @include transform(rotate(180deg));
        }
      }
      &.slick-next {
        margin-bottom: 0;
      }
      &:hover {
        opacity: 1;
        @include transition(opacity ease-out 0.25s);
      }
    }
    &:before {
      content: "";
      height: 2px;
      background: #359675;
      position: absolute;
      width: 160px;
      right: -60px;
      display: block;
      top: 0;
      bottom: 0;
      margin: auto;
      opacity: 0.5;
    }
  }
}
.homeNews {
  position: relative;
  padding: 105px 0;
  .top_text {
    h1 {
      font-size: 48px;
      color: $c_ckb;
      margin-bottom: 35px;
    }
  }
  .listnews {
    @extend .after_clear;
    .item {
      width: 23.125%;
      float: left;
      margin-right: 2.5%;
      a {
        .dte {
          color: $c_999;
          margin-bottom: 10px;
        }
        h6 {
          font-weight: 800;
          color: $c_333;
          font-size: 19px;
          line-height: 24px;
          margin-bottom: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-inline-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          min-height: 72px;
          max-height: 72px;
        }
        p {
          font-size: 13px;
          color: #666;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-inline-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          min-height: 80px;
          max-height: 80px;
        }
      }
      .read {
        margin-top: 10px;
      }
      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  }
}
.homeCareer {
  background: #f5f5f5;
  position: relative;
  overflow: hidden;
  .txt_career {
    padding: 112px 0 85px;
    width: 470px;
    .lines {
      position: relative;
      height: 2px;
      background: $c_ckb;
      display: block;
      margin: 30px 0 40px;
      max-width: 185px;
      left: -95px;
      width: 100%;
    }
    p {
      font-size: 19px;
      line-height: 30px;
      color: $c_666;
    }
    .continue {
      margin-top: 20px;
    }
  }
  .img_career {
    position: absolute;
    top: 0;
    right: 0;
    width: 51%;
    bottom: 0;
    img {
      display: block;
      max-width: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.trigger {
  height: 1px;
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  &.trigger-1 {
    top: 25%;
    bottom: auto;
  }
}

/* middle
----------------------------------------------------------------------------------------------*/
.middle {
  padding: 100px 0;
  position: relative;
  background: url("../images/material/bg_middle_pg.png") no-repeat bottom center;
  background-size: 100%;
  .wrapper {
    width: 1370px;
  }
  &.no_bg {
    background: none;
  }
  &.history {
    background: url("../images/material/bg_middle_history.png") no-repeat bottom
      center;
  }
  &.karir {
    padding-top: 0;
  }
  &.no_pd {
    padding-bottom: 0;
  }
  &.inquiry {
    background: $c_ckb;
  }
}

.banner {
  position: relative;
  z-index: 2;
  .images {
    img {
      display: block;
      width: 100%;
    }
  }
  .title_pg {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    @include transform(translateY(-50%));
    h1,
    h2 {
      font-weight: 800;
      font-size: 57px;
      line-height: 60px;
      color: $c_ckb;
    }
  }
  &.thanks {
    .txt_thanks {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 110px;
      margin: auto;
      h1 {
        width: 640px;
        padding-bottom: 20px;
        margin-bottom: 25px;
        position: relative;
        color: #fff;
        &:after {
          background: url("../images/material/ico_box-box.png") no-repeat top
            center;
        }
        &:before {
          height: 2px;
          display: inline-block;
          width: 200px;
          left: -100px;
          content: "";
          background: #fff;
          display: block;
          bottom: 0;
          top: auto;
          position: absolute;
        }
      }
      p {
        width: 640px;
        font-size: 19px;
        color: #fff;
        line-height: 30px;
        margin-bottom: 25px;
      }
      &.txt_404 {
        bottom: 0;
        top: 50%;
        margin: auto;
        text-align: center;
        @include transform(translateY(-50%));
        h1 {
          color: #fff;
          font-size: 150px;
          line-height: normal;
          text-align: center;
          margin: 0 auto 30px;
          &:before {
            background: #fff;
            left: 0;
            right: 0;
            margin: auto;
          }
          &:after {
            background: url("../images/material/ico_box-box.png") no-repeat top
              center;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
        p {
          margin: 0 auto 50px;
        }
      }
    }
  }
}
.nav_sub {
  width: 320px;
  margin-top: -60px;
  position: relative;
  z-index: 3;
  .sub_act {
    background: #156146;
    height: 60px;
    border-left: 3px solid $c_yllow;
    padding: 0 65px 0 30px;
    position: relative;
    cursor: pointer;
    span {
      font-size: 13px;
      color: #52c69e;
      font-family: $f_work;
      display: inline-block;
      font-weight: 700;
      position: absolute;
      top: 50%;
      left: 30px;
      right: 65px;
      @include transform(translateY(-50%));
      line-height: 16px;
    }
    i {
      position: absolute;
      width: 60px;
      height: 60px;
      top: 0;
      right: 0;
      background: #13523c;
      &:after {
        width: 26px;
        height: 14px;
        content: "";
        background: url("../images/material/arr_sub.png") no-repeat;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        @include transition(all ease-out 0.25s);
      }
    }
    &.act {
      i {
        &:after {
          @include transform(rotate(-180deg));
          @include transition(all ease-out 0.3s);
        }
      }
    }
  }
  .drop_sub {
    position: absolute;
    top: 100%;
    display: none;
    ul {
      li {
        a {
          padding: 20px 15px 20px 30px;
          width: 260px;
          @include boxSizing(border-box);
          display: block;
          background: #13523c;
          color: #66a18c;
          font-size: 13px;
          font-weight: 700;
          @include transition(all ease-out 0.25s);
          &:hover,
          &.active {
            @include transition(all ease-out 0.25s);
            background: #156146;
          }
        }
        &.back {
          a {
            color: #fff;
            border-top: 0.5px solid transparent;
            i {
              display: inline-block;
              vertical-align: middle;
              width: 9px;
              height: 13px;
              background: url("../images/material/arr_sub_mn.png") no-repeat
                center;
              margin-right: 10px;
            }
            &:hover {
              /*border-color: #fff;*/
            }
          }
        }
      }
    }
  }
  &.office {
    width: 100%;
    ul {
      @extend .after_clear;
      li {
        float: left;
        width: 25%;
        position: relative;
        border-right: 2px solid #bfe4da;
        @include boxSizing(border-box);
        a {
          display: block;
          padding: 25px 10px 15px;
          text-align: center;
          font-size: 16px;
          font-weight: 800;
          text-transform: uppercase;
          color: #333;
          background: #fff;
          line-height: 20px;
          position: relative;
          &.active {
            background: $c_yllow;
            &:before {
              background: #fff;
            }
          }
          &:hover {
          }
          &:before {
            content: "";
            height: 10px;
            background: $c_yllow;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            display: block;
          }
        }
      }
    }
  }
}

.inner_content {
  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
    color: $c_333;
  }
}
.title_content {
  text-align: center;
  padding-top: 70px;
  h1 {
    padding-bottom: 35px;
    position: relative;
    margin-bottom: 45px;
    &:before {
      content: "";
      width: 200px;
      height: 2px;
      background: $c_ckb;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      top: auto;
    }
  }
}
.wrap_text {
  width: 830px;
  margin: 0 auto;
  p {
    font-size: 16px;
    line-height: 24px;
    color: $c_333;
    margin-bottom: 30px;
  }
  > h3 {
    font-size: 28px;
    color: $c_ckb;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 20px;
  }
  > ul {
    margin-bottom: 40px;
    li {
      font-size: 16px;
      line-height: 26px;
      padding-left: 55px;
      position: relative;
      margin-bottom: 15px;
      &:before {
        width: 8px;
        height: 8px;
        background: #95b7ab;
        position: absolute;
        left: 25px;
        top: 10px;
        display: block;
        content: "";
      }
    }
  }
  > ol {
    padding-left: 25px;
    list-style: decimal;
    li {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 15px;
    }
  }
  &.center {
    > p {
      text-align: center;
    }
  }
}
.over_wrap {
  margin: 0 -270px 50px;
  &.manag {
    position: relative;
  }
}
.listing {
  @extend .after_clear;
  .item {
    float: left;
    position: relative;
    display: block;
    > a {
      display: block;
      @extend .after_clear;
      position: relative;
    }
    .img {
      position: relative;
      overflow: hidden;
      float: left;
      img {
        display: block;
        @include transition(transform ease-out 0.8s);
        width: 100%;
      }
    }
    .ic_box {
      font-size: 23px;
      line-height: 32px;
      padding-top: 25px;
      color: $c_333;
      margin-bottom: 20px;
    }
    p {
      color: $c_666;
      font-size: 16px;
    }
    .detail {
      font-size: 15px;
      color: $c_ckb;
      text-transform: uppercase;
      font-family: $f_work;
      font-weight: 700;
      display: inline-block;
      &:hover {
        color: #47b28d;
      }
    }
    &:hover {
      .ic_box {
        color: $c_ckb;
      }
      .img {
        img {
          @include transition(transform ease-out 5s);
          @include transform(scale(1.15));
        }
      }
    }
  }
  &.col2 {
    .item {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 50px;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }
  &.col3 {
    .item {
      width: 31%;
      margin-right: 3.5%;
      margin-bottom: 50px;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
  &.listWho,
  &.listCr {
    .img {
      float: none;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: #006766;
        opacity: 0;
        @include transition(opacity ease-out 0.2s);
      }
    }
    .dec {
      padding-top: 25px;
      h6 {
        font-weight: 700;
        font-size: 28px;
        color: #135f45;
        margin-bottom: 10px;
        @include transition(color 0.25s ease-out);
        overflow: hidden;
        height: 58px;
      }
      p {
        font-size: 13px;
        line-height: 21px;
        margin-bottom: 0;
        height: 84px;
        overflow: hidden;
      }
    }
    .item {
      &:hover {
        .img {
          &:after {
            @include transition(opacity ease-out 0.2s);
            opacity: 0.5;
          }
        }
        .dec {
          h6 {
            color: #47b28d;
            @include transition(color 0.25s ease-out);
          }
        }
      }
    }
  }
  &.listCr {
    .dec {
      h6 {
        font-size: 33px;
        line-height: 36px;
        margin-bottom: 15px;
      }
      p {
        font-size: 16px;
        line-height: 26px;
      }
      .detail {
        margin-top: 30px;
      }
    }
  }
  &.logistic {
    .item {
      &.bl {
        .img {
          &:after {
            background: #1d45d6;
          }
        }
      }
      &.rd {
        .img {
          &:after {
            background: #fb0d0d;
          }
        }
      }
      &.or {
        .img {
          &:after {
            background: #e5880f;
          }
        }
      }
      &.gr {
        .img {
          &:after {
            background: #17ae65;
          }
        }
      }
    }
  }
  &.listService {
    margin-top: 70px;
    .item {
      width: 48.5%;
      margin-right: 3%;
      @extend .after_clear;
      margin-bottom: 60px;
      .img {
        float: left;
        width: 300px;
      }
      .text {
        float: right;
        width: 320px;
        position: relative;
        &.v_center {
          position: relative;
          right: 0;
          left: auto;
        }
        h3 {
          text-transform: uppercase;
        }
        p {
          margin-bottom: 0;
          max-height: 92px;
          overflow: hidden;
        }
      }
    }
  }
  &.listIntegrated {
    margin: 60px 0;
    .item {
      text-align: center;
      width: 22%;
      margin-right: 4%;
      padding: 0 20px;
      @include boxSizing(border-box);
      margin-bottom: 20px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .ico {
        margin-bottom: 25px;
      }
      h6 {
        font-size: 16px;
        font-weight: 600;
        color: $c_ckb;
        line-height: 24px;
      }
    }
    &.col5 {
      .item {
        width: 19.5%;
        margin-right: 0.625%;
        padding: 0 10px;
        &:nth-child(4n) {
          margin-right: 0.625%;
        }
        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  }
  &.subIntegrate {
    margin: 40px 0;
    .item {
      float: none;
      margin-bottom: 45px;
      cursor: pointer;
      .text {
        width: 390px;
        h3 {
          font-size: 26px;
          color: $c_ckb;
          font-weight: 800;
          margin-bottom: 15px;
        }
        &.v_center {
          position: absolute;
          right: 0;
          left: auto;
          @include transform(translateY(-50%));
          top: 50%;
        }
        p {
          max-height: 120px;
          overflow: hidden;
        }
      }
    }
  }
  &.listIndustry {
    margin: 60px 0;
    .item {
      width: 31%;
      margin-right: 3.5%;
      margin-bottom: 50px;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      .images {
        position: relative;
        overflow: hidden;
        img {
          display: block;
        }
        > img {
          width: 100%;
        }
        .txt_img {
          position: absolute;
          left: 20px;
          right: 20px;
          top: 50%;
          @include transform(translateY(-50%));
          text-align: center;
          z-index: 3;
          @include transition(opacity ease-out 0.25s);
          img {
            display: block;
            margin: 0 auto 40px;
          }
          h6 {
            color: #fff;
            font-size: 23px;
            text-transform: capitalize;
          }
        }
        &:after {
          content: "";
          background: rgba(0, 0, 0, 0.6);
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 2;
          @include transition(all ease-out 0.25s);
        }
        .bg {
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 2;
          @include transition(all ease-out 0.25s);
          opacity: 0;
        }
      }
      .txt_hover {
        display: block;
        position: absolute;
        left: 40px;
        right: 40px;
        top: 50%;
        @include transform(translateY(-50%));
        z-index: 4;
        text-align: center;
        opacity: 0;
        margin-top: 30px;
        @include transition(all ease-out 0.15s);
        font-size: 16px;
        color: #fff;
        p {
          font-size: 16px;
          color: #fff;
          margin-bottom: 0;
        }
      }
      &:hover {
        .images {
          .txt_img {
            opacity: 0;
            @include transition(opacity ease-out 0.25s);
          }
          &:after {
            @include transition(all ease-out 0.25s);
            opacity: 0;
          }
          .bg {
            opacity: 0.75;
            @include transition(opacity ease-out 0.25s);
          }
        }
        .txt_hover {
          display: block;
          opacity: 1;
          margin-top: 0;
          @include transition(all ease-out 0.35s);
        }
      }
      /*&.mining {
                &:hover {
                    .images {
                        &:after {background: $c_ind_yl; opacity: 0.75;}
                    }
                }
            }
            &.oil {
                &:hover {
                    .images {
                        &:after {background: $c_ind_bl2; opacity: 0.75;}
                    }
                }
            }
            &.contruct {
                &:hover {
                    .images {
                        &:after {background: $c_ind_gr; opacity: 0.75;}
                    }
                }
            }
            &.auto {
                &:hover {
                    .images {
                        &:after {background: $c_ind_red; opacity: 0.75;}
                    }
                }
            }
            &.manufac {
                &:hover {
                    .images {
                        &:after {background: $c_ind_ung; opacity: 0.75;}
                    }
                }
            }
            &.aviat {
                &:hover {
                    .images {
                        &:after {background: $c_ind_bl; opacity: 0.75;}
                    }
                }
            }
            &.other {
                &:hover {
                    .images {
                        &:after {background: $c_ind_ab; opacity: 0.75;}
                    }
                }
            }*/
    }
  }
}
/*.v_center {
    position: relative; top: 50%; left: 0; right: 0; display: block; @include transform(translateY(-50%));
}*/

.sliderContent {
  position: relative;
  .slick-arrow {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    height: 75px;
    width: 110px;
    font-size: 0;
    background: #fff;
    border: none;
    padding: 0;
    cursor: pointer;
    &:after {
      width: 12px;
      height: 19px;
      background: url("../images/material/arr_slideContent.png") no-repeat left
        center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: "";
      margin: auto;
      @include transition(all linear 0.25s);
    }
    &.slick-next {
      right: 0;
      &:after {
        @include transform(rotate(-180deg));
      }
      &:before {
        content: "";
        width: 1px;
        background: #999;
        height: 40px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.6;
      }
    }
    &.slick-prev {
      right: 110px;
    }
    &:hover {
      &:after {
        @include transition(all linear 0.25s);
        background-position: right center !important;
      }
    }
  }
  &.capt {
    .slide {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 2;
        background: url("../images/material/shdow_bgslide.png") no-repeat bottom
          center;
        background-size: 100%;
      }
      .captext {
        position: absolute;
        z-index: 3;
        color: #fff;
        bottom: 25px;
        left: 50px;
        width: 60%;
        p {
          color: #fff;
          margin: 0;
          font-weight: 700;
        }
      }
    }
  }
}

.sec_bottom {
  margin: 40px 0 -100px;
  padding-bottom: 100px;
  position: relative;
  .ic_box {
    font-size: 40px;
    line-height: 55px;
    padding-top: 20px;
    margin-bottom: 30px;
  }
  .slideRecommend {
    position: relative;
    z-index: 2;
    .slick-list {
      padding: 0 15px;
    }
    .item {
      float: left;
      margin-right: 38px;
      width: 420px;
      .img {
        @include transition(box-shadow ease-out 0.25s);
        img {
          display: block;
          width: 100%;
        }
      }
      .text {
        padding: 35px 30px 10px;
        color: #fff;
        @include transition(color ease-out 0.25s);
        h6 {
          font-size: 23px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        p {
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 0;
          height: 78px;
          overflow: hidden;
        }
      }
      &:hover {
        .img {
          @include boxShadow(0 0 30px -5px rgba(0, 0, 0, 0.6));
          @include transition(box-shadow ease-out 0.25s);
        }
        .text {
          color: #222;
          @include transition(color ease-out 0.25s);
        }
      }
    }
    .slick-arrow {
      position: absolute;
      top: 135px;
      margin: auto;
      z-index: 3;
      width: 60px;
      height: 60px;
      background: #fff;
      border: none;
      padding: 0;
      font-size: 0;
      @include boxShadow(0 0 20px -2px rgba(0, 0, 0, 0.35));
      cursor: pointer;
      &:after {
        width: 12px;
        height: 19px;
        background: url("../images/material/arr_slideContent.png") no-repeat
          left center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        content: "";
        margin: auto;
        @include transition(all linear 0.25s);
      }
      &.slick-next {
        right: -110px;
        &:after {
          @include transform(rotate(-180deg));
        }
      }
      &.slick-prev {
        left: -110px;
      }
      &:hover {
        &:after {
          @include transition(all linear 0.25s);
          background-position: right center !important;
        }
      }
    }
  }
  .bg_recomnd {
    display: block;
    height: 400px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    &.hide {
      display: none;
    }
  }
  &:after {
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    height: 400px;
    display: block;
    position: absolute;
    z-index: 1;
    background-size: cover;
    display: none;
  }
  &.ind_1 {
    &:after {
      background: url("../images/material/bg_sec_btm_01.png") no-repeat bottom
        center;
    }
  }
  &.ind_2 {
    &:after {
      background: url("../images/material/bg_sec_btm_02.png") no-repeat bottom
        center;
    }
  }
  &.ind_3 {
    &:after {
      background: url("../images/material/bg_sec_btm_03.png") no-repeat bottom
        center;
    }
  }
  &.ind_4 {
    &:after {
      background: url("../images/material/bg_sec_btm_04.png") no-repeat bottom
        center;
    }
  }
  &.ind_5 {
    &:after {
      background: url("../images/material/bg_sec_btm_05.png") no-repeat bottom
        center;
    }
  }
  &.ind_6 {
    &:after {
      background: url("../images/material/bg_sec_btm_06.png") no-repeat bottom
        center;
    }
  }
  &.ind_7 {
    &:after {
      background: url("../images/material/bg_sec_btm_07.png") no-repeat bottom
        center;
    }
  }
  &.hide {
    display: none;
  }
}

.about_sec {
  padding: 130px 0;
  position: relative;
  @include boxSizing(border-box);
  .text_about {
    .ic_box {
      padding-bottom: 25px;
      position: relative;
      margin-bottom: 35px;
      i {
        height: 2px;
        background: $c_ckb;
        width: 200px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: auto;
        top: auto;
      }
    }
    p {
      font-size: 19px;
      line-height: 30px;
    }
    a.continue {
      margin-top: 30px;
    }
  }
  .img_compli {
    position: absolute;
    right: 0;
    top: 0;
  }
  .img_respons {
    position: absolute;
    left: 0;
    top: 0;
    img {
      position: relative;
      z-index: 2;
      display: block;
    }
    .bg_green {
      width: 475px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: $c_ckb;
      z-index: 1;
    }
  }
  &.who {
    .text_about {
      width: 600px;
      padding-right: 100px;
      padding-top: 40px;
      p {
        margin-right: 20px;
      }
    }
  }
  &.group {
    background: url("../images/material/bg_group.jpg") repeat-y;
    .wrap_text {
      text-align: center;
      .ic_box {
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
        &:after {
          background-position: top center;
        }
      }
      p {
        color: #fff;
        font-size: 19px;
        line-height: 30px;
      }
      .continue {
        color: #f4cb50;
        span {
          background: #f4cb50;
        }
      }
    }
  }
  &.compliance {
    min-height: 765px;
    overflow: hidden;
    .text_about {
      width: 600px;
      margin-top: 125px;
    }
  }
  &.respons {
    min-height: 825px;
    .text_about {
      width: 700px;
      margin-top: 100px;
    }
  }
  &.management {
    background: #ededed;
    padding-top: 80px;
    margin-bottom: -100px;
    .wrap_text {
      .ic_box {
        text-align: center;
        margin-bottom: 30px;
      }
    }
  }
  &:last-of-type {
    margin-bottom: -100px;
  }
}
.wrp_btn {
  text-align: center;
  margin-top: 20px;
}

.sliderGroup {
  margin-top: 40px;
  .slide {
    width: 440px;
    margin-right: 27px;
    .img {
      img {
        width: 100%;
        display: block;
      }
    }
    .dec {
      padding: 25px 25px 0 0;
      h6 {
        font-size: 28px;
        font-weight: 800;
        color: #fff;
        margin-bottom: 15px;
        line-height: 32px;
        height: 66px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        margin: 0;
        font-size: 16px;
        line-height: 26px;
        color: #fff;
        font-weight: 300;
        height: 104px;
        overflow: hidden;
      }
    }
  }
  .slick-arrow {
    width: 60px;
    height: 60px;
    border: 2px solid #fff;
    background: none;
    font-size: 0;
    position: absolute;
    top: 30%;
    bottom: auto;
    margin: auto;
    z-index: 3;
    cursor: pointer;
    @include transition(border-color ease-out 0.25s);
    &.slick-prev {
      left: -110px;
    }
    &.slick-next {
      right: -110px;
      &:after {
        @include transform(rotate(-180deg));
      }
    }
    &:after {
      content: "";
      width: 12px;
      height: 19px;
      background: url("../images/material/arr_slideAbout.png") no-repeat left
        center;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      @include transition(all ease-out 0.25s);
    }
    &:hover {
      border-color: $c_yllow;
      @include transition(border-color ease-out 0.25s);
      &:after {
        background-position: right center;
        @include transition(all ease-out 0.25s);
      }
    }
  }
}
.listManagent {
  @extend .after_clear;
  .item {
    float: left;
    padding: 0 1px;
    width: 50%;
    @include boxSizing(border-box);
    margin-bottom: 40px;
    position: relative;
    figure {
      position: relative;
      display: block;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: block;
        background: url("../images/material/bg_manag.png") no-repeat bottom
          center;
        background-size: 100%;
      }
      img {
        display: block;
        @include transition(all ease-out 0.3s);
      }
    }
    .txt {
      position: absolute;
      bottom: 30px;
      left: 20px;
      color: #fff;
      width: 250px;
      @include transition(all ease-out 0.3s);
      h2 {
        font-size: 33px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 40px;
        margin-bottom: 15px;
      }
      h6 {
        font-size: 16px;
        margin: 0;
      }
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:hover {
      figure {
        img {
          @include transition(all ease-out 1s);
          @include transform(scale(1.15));
        }
      }
      .txt {
        @include transition(all ease-out 0.3s);
        left: 30px;
        bottom: 40px;
      }
    }
  }
}

.listVisi {
  h3 {
    font-size: 28px;
    color: $c_ckb;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 20px;
  }
  .list {
    @extend .after_clear;
    margin-bottom: 40px;
    .ico {
      float: left;
      width: 55px;
    }
    .dec {
      display: block;
      margin-left: 90px;
    }
  }
  ul {
    margin-bottom: 20px;
    li {
      b {
        display: block;
      }
      font-size: 16px;
      line-height: 26px;
      padding-left: 25px;
      position: relative;
      margin-bottom: 15px;
      &:before {
        width: 8px;
        height: 8px;
        background: #95b7ab;
        position: absolute;
        left: 0px;
        top: 8px;
        display: block;
        content: "";
      }
    }
  }
}

.slideHistory {
  margin-top: 50px;
}

.slideYear {
  margin: 0 30px 70px;
  .slick-list {
    padding: 0px !important;
  }
  .year {
    border-bottom: 2px solid #aaa;
    width: 20%;
    text-align: center;
    position: relative;
    height: 160px;
    h3 {
      position: absolute;
      padding-top: 50px;
      text-align: center;
      font-size: 23px;
      @include transition(all ease-out 0.5s);
      font-weight: 700;
      color: #9a9a9a;
      left: 50%;
      @include transform(translateX(-50%));
      font-family: "Open Sans", sans-serif;
    }
    &.slick-center {
      h3 {
        padding-top: 0;
        font-size: 100px;
        font-weight: 700;
        color: $c_ckb;
        @include transition(all ease-out 0.5s);
      }
      &:before {
        height: 60px;
        @include transition(height ease-out 0.4s);
      }
    }
    &:before {
      content: "";
      width: 1px;
      height: 40px;
      background: #9a9a9a;
      display: inline-block;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      margin: auto;
      z-index: 2;
      @include transition(height ease-out 0.4s);
    }
  }
  .slick-arrow {
    position: absolute;
    width: 10px;
    height: 14px;
    background: url("../images/material/arr_history.png") no-repeat right center;
    padding: 0;
    border: none;
    font-size: 0;
    top: auto;
    bottom: 20px;
    left: -20px;
    right: auto;
    z-index: 2;
    cursor: pointer;
    &.slick-next {
      right: -20px;
      left: auto;
    }
    &.slick-prev {
      @include transform(rotate(-180deg));
    }
  }
}
.slideText {
  width: 570px;
  margin: 0 auto;
  .text_history {
    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    ul {
      li {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
        padding-left: 25px;
        position: relative;
        &:before {
          content: "";
          width: 8px;
          height: 8px;
          background: #95b7ab;
          position: absolute;
          left: 0;
          top: 8px;
        }
      }
    }
    .wrap {
      position: relative;
    }
  }
}

.listAward {
  width: 1170px;
  margin: 60px auto 0;
  .list {
    @extend .after_clear;
    width: 70%;
    position: relative;
    margin-bottom: 70px;
    figure {
      width: 300px;
      float: left;
      position: relative;
      @include boxShadow(4px 4px 30px -10px rgba(0, 0, 0, 0.3));
      background: #fff;
      img {
        display: block;
        width: 260px;
        margin: 0 auto;
        padding: 10px 0;
      }
    }
    .ket_award {
      display: block;
      margin-left: 330px;
      h5 {
        font-size: 19px;
        font-weight: 700;
        color: $c_333;
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 22px;
        &:after {
          content: "";
          height: 1px;
          background: $c_ckb;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 105px;
        }
      }
      p {
        margin-bottom: 12px;
        font-weight: 600;
      }
      .by {
        display: block;
        font-size: 16px;
        color: $c_999;
      }
    }
    &:nth-child(2n) {
      margin-left: 25%;
    }
  }
}

.listFact {
  margin-top: 60px;
  .item {
    text-align: center;
    width: 23%;
    margin-right: 2.333%;
    padding: 0 10px;
    @include boxSizing(border-box);
    margin-bottom: 60px;
    float: none;
    display: inline-block;
    vertical-align: top;
    &:nth-child(4n) {
      margin-right: 0;
    }
    h2 {
      color: $c_ckb;
      font-weight: 700;
      font-size: 57px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 0;
      B {
        font-weight: 600;
      }
    }
  }
}

.btm_map {
  margin: 20px 0 -100px;
}
#map_logistic {
  height: 550px;
}

.subCompliance {
  @extend .after_clear;
  position: relative;
  margin: 40px 0;
  .box {
    float: left;
    width: 50%;
    padding-right: 8.4%;
    text-align: center;
    position: relative;
    @include boxSizing(border-box);
    &:nth-child(2) {
      margin-right: 0;
      padding-right: 0;
      padding-left: 8.4%;
      &:before {
        display: none;
      }
    }
    .ico {
      width: 180px;
      margin: 0 auto 30px;
      display: block;
      img {
        @include transition(all ease-out 0.4s);
        display: block;
        position: relative;
      }
    }
    &:before {
      content: "";
      width: 2px;
      background: #ccc;
      position: absolute;
      left: auto;
      right: 0;
      top: 50px;
      bottom: 50px;
      margin: auto;
      display: block;
    }
    h5 {
      font-size: 28px;
      color: $c_ckb;
      font-weight: 800;
      margin-bottom: 20px;
      @include transition(color ease-out 0.25s);
    }
    p {
      margin-bottom: 0;
    }
    &:hover {
      h5 {
        color: #47b28d;
        @include transition(color ease-out 0.25s);
      }
      .ico {
        img {
          @include transform(scale(1.065));
          @include transition(all ease-out 0.4s);
        }
      }
    }
  }
}

.content_Compliance {
  width: 1030px;
  margin: 0 auto;
  .tab_nav {
    text-align: center;
    .tab {
      width: 350px;
      display: inline-block;
      position: relative;
      margin: 0 35px;
      padding-bottom: 30px;
      cursor: pointer;
      .ico {
        margin-bottom: 15px;
        img {
          display: block;
          margin: 0 auto;
          @include filter(grayscale(100%));
        }
      }
      h5 {
        font-size: 28px;
        color: #aaa;
        font-weight: 700;
        @include transition(color ease-out 0.25s);
      }
      &.active,
      &:hover {
        .ico {
          img {
            @include filter(grayscale(0%));
          }
        }
        h5 {
          @include transition(color ease-out 0.25s);
          color: $c_ckb;
          margin: 0;
        }
        &:after {
          border-color: $c_yllow;
        }
      }
      &:after {
        content: "";
        width: 26px;
        height: 26px;
        background: #fff;
        border: 8px solid #aaa;
        display: block;
        margin: auto;
        z-index: 2;
        @include boxSizing(border-box);
        @include borderRadius(50%);
        position: absolute;
        left: 0;
        right: 0;
        bottom: -13px;
      }
    }
  }
  .box_white {
    display: block;
    background: #fff;
    border: 3px solid #aaa;
    padding: 80px;
    position: relative;
    z-index: 1;
    .content_tab {
      ul {
        margin-bottom: 25px;
        li {
          font-size: 16px;
          line-height: 26px;
          padding-left: 30px;
          position: relative;
          margin-bottom: 15px;
          &:before {
            width: 8px;
            height: 8px;
            background: #95b7ab;
            position: absolute;
            left: 0px;
            top: 8px;
            display: block;
            content: "";
          }
        }
      }
      > h3 {
        font-size: 28px;
        color: #006766;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 20px;
      }
    }
    &:after {
      content: "";
      width: 216px;
      height: 40px;
      background: url("../images/material/bg_material_white.png") no-repeat;
      position: absolute;
      right: 50px;
      bottom: -20px;
      display: block;
    }
  }
  .slideSertifikat {
    margin-top: 60px;
    position: relative;
    .slide {
      @extend .after_clear;
      padding: 10px 0;
      figure {
        width: 300px;
        float: left;
        position: relative;
        @include boxShadow(4px 4px 30px -10px rgba(0, 0, 0, 0.3));
        background: #fff;
        img {
          display: block;
          width: 260px;
          margin: 0 auto;
          padding: 10px 0;
        }
      }
      .ket_award {
        display: block;
        margin-left: 330px;
        h5 {
          font-size: 19px;
          font-weight: 700;
          color: $c_333;
          position: relative;
          padding-bottom: 15px;
          margin-bottom: 22px;
          &:after {
            content: "";
            height: 1px;
            background: $c_ckb;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 105px;
          }
        }
        p {
          margin-bottom: 12px;
          font-weight: 600;
        }
        .by {
          display: block;
          font-size: 16px;
          color: $c_999;
        }
      }
    }
    .slick-arrow {
      width: 16px;
      height: 16px;
      background: url("../images/material/arr_slideSertificate.png") no-repeat
        center;
      position: absolute;
      right: 0;
      bottom: 10px;
      z-index: 3;
      font-size: 0;
      border: none;
      padding: 0;
      cursor: pointer;
      &.slick-prev {
        right: 40px;
        @include transform(rotate(-180deg));
      }
    }
  }
}

.healthlist {
  .item {
    @extend .after_clear;
    .ico {
      width: 110px;
      float: left;
    }
    .dec {
      margin-left: 140px;
      display: block;
      margin-right: 10%;
      @include transform(translateY(-50%));
      position: absolute;
      top: 50%;
      p {
        margin-bottom: 0;
        max-height: 110px;
        overflow: hidden;
      }
    }
  }
}

.slideCSR {
  position: relative;
  .sliderCapt {
    position: absolute;
    right: 0;
    width: 65%;
    bottom: 0;
    .slick-list {
      &:before {
        background: #fff;
        position: absolute;
        left: 0;
        right: 0;
        height: 70%;
        display: block;
        content: "";
        top: auto;
        bottom: 0;
      }
    }
    .capt {
      position: relative;
      padding: 85px 0 20px 45px;
      h2 {
        font-size: 29px;
        line-height: 36px;
        width: 70%;
        color: #333;
        font-weight: 700;
      }
      .lines {
        width: 5px;
        background: $c_yllow;
        display: block;
        position: absolute;
        left: 20px;
        height: 90%;
        z-index: 3;
        top: 0;
        bottom: 0;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    bottom: 15px;
    right: 0;
    z-index: 3;
    height: 75px;
    width: 110px;
    font-size: 0;
    background: #fff;
    border: none;
    padding: 0;
    cursor: pointer;
    &:after {
      width: 12px;
      height: 19px;
      background: url("../images/material/arr_slideContent.png") no-repeat left
        center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: "";
      margin: auto;
      @include transition(all linear 0.25s);
    }
    &.slick-next {
      right: 0;
      &:after {
        @include transform(rotate(-180deg));
      }
      &:before {
        content: "";
        width: 1px;
        background: #999;
        height: 40px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.6;
      }
    }
    &.slick-prev {
      right: 110px;
    }
    &:hover {
      &:after {
        @include transition(all linear 0.25s);
        background-position: right center !important;
      }
    }
  }
}

.accordion {
  .title_acc {
    padding: 25px;
    position: relative;
    border-bottom: 2px solid #ccc;
    cursor: pointer;
    h5 {
      font-size: 19px;
      font-weight: 700;
      color: $c_ckb;
      width: 90%;
    }
    .ic_plus {
      position: absolute;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 30px;
      &:after,
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        background: $c_ckb;
      }
      &:before {
        height: 4px;
        top: 0;
        bottom: 0;
      }
      &:after {
        width: 4px;
        top: 0;
        bottom: 0;
        height: 100%;
        @include transition(all ease-out 0.25s);
      }
    }
    &.active {
      .ic_plus {
        &:after {
          height: 0;
          @include transition(all ease-out 0.25s);
        }
      }
    }
  }
  .dec_acc {
    font-family: Montserrat, sans-serif !important;
    padding: 25px 50px 25px 25px;
    display: none;
    p {
      font-family: Montserrat, sans-serif !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span {
      font-family: Montserrat, sans-serif !important;
    }
  }
}

.slideMangement {
  .slide {
    position: relative;
    @extend .after_clear;
    padding: 15px;
    figure {
      display: block;
      float: left;
      position: relative;
      @include boxShadow(0 0 30px -8px rgba(0, 0, 0, 0.6));
    }
    .txt {
      margin-left: 540px;
      display: block;
      position: relative;
      top: 0;
      @include transform(translateY(0));
      width: 660px;
      h2 {
        font-size: 28px;
        color: $c_ckb;
        font-weight: 700;
        text-transform: capitalize;
        padding-bottom: 25px;
        position: relative;
        margin-bottom: 20px;
        span {
          display: block;
          font-size: 13px;
          color: #231010;
          font-weight: 400;
          text-transform: capitalize;
          margin-top: 10px;
        }
        &:after {
          content: "";
          height: 2px;
          width: 130px;
          position: absolute;
          left: -70px;
          display: block;
          background: $c_ckb;
          bottom: 0;
        }
      }
    }
  }
}
.nav_slideMagement {
  position: absolute;
  right: 0;
  top: 45%;
  @include transform(translateY(-50%));
  .slick-arrow {
    position: relative;
    z-index: 2;
    margin: auto;
    right: 0;
    width: 50px;
    height: 50px;
    padding: 0;
    font-size: 0;
    border: 1px solid #006766;
    @include borderRadius(50%);
    background: none;
    @include transition(opacity ease-out 0.25s);
    cursor: pointer;
    display: block;
    margin: 40px 0;
    &:after {
      width: 13px;
      height: 16px;
      background: url("../images/material/arr_round_slide.png") no-repeat center
        center;
      position: absolute;
      content: "";
      display: block;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &.slick-prev {
      margin-top: 0;
      &:after {
        @include transform(rotate(180deg));
      }
    }
    &.slick-next {
      margin-bottom: 0;
    }
    &:hover {
      opacity: 1;
      @include transition(opacity ease-out 0.25s);
    }
  }
  &:before {
    content: "";
    height: 2px;
    background: #359675;
    position: absolute;
    width: 100px;
    right: -20px;
    display: block;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.5;
  }
}

.sec_news {
  @extend .after_clear;
  padding: 75px 70px;
  position: relative;
  .img_news {
    width: 56%;
    float: left;
    position: relative;
    img {
      display: block;
      width: 100%;
    }
  }
  .text_news {
    width: 40%;
    float: right;
    @include boxSizing(border-box);
    padding-right: 90px;
    .ic_box {
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 40px;
      &:before {
        width: 200px;
        height: 2px;
        background: $c_ckb;
        position: absolute;
        left: 0;
        bottom: 0;
        top: auto;
        content: "";
      }
    }
    p {
      font-size: 19px;
      line-height: 30px;
    }
    .newslisting {
      .list {
        padding: 0 70px 20px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 25px;
        .dt {
          font-size: 13px;
          color: #999;
          display: block;
          margin-bottom: 10px;
        }
        h6 {
          font-size: 16px;
          line-height: 24px;
          color: $c_333;
          font-weight: 700;
          margin-bottom: 10px;
          @include transition(color ease-out 0.25s);
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-inline-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          max-height: 72px;
        }
        p {
          font-size: 15px;
          line-height: 24px;
          color: $c_333;
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-inline-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          max-height: 72px;
        }
        &:hover {
          h6 {
            color: $c_ckb2;
            @include transition(color ease-out 0.25s);
          }
        }
      }
    }
    .continue {
      font-size: 13px;
      line-height: 14px;
      margin-top: 30px;
      span {
        height: 14px;
        width: 14px;
        background-size: 100%;
      }
    }
  }
  &.indust {
    background: #efefef;
    .img_news {
      float: right;
    }
    .text_news {
      float: left;
      padding-left: 90px;
      padding-right: 0;
    }
  }
}

.pg_news {
  padding-top: 70px;
  > .ic_box {
    padding-bottom: 20px;
    margin-bottom: 30px;
    position: relative;
    &:before {
      content: "";
      height: 2px;
      background: $c_ckb;
      position: absolute;
      width: 200px;
      left: 0;
      bottom: 0;
    }
  }
  &.detail {
    .txt_filter {
      margin-bottom: 30px;
    }
    > .images {
      margin-bottom: 40px;
    }
  }
}

.txt_filter {
  @extend .after_clear;
  margin-bottom: 60px;
  .left {
    width: 900px;
    p {
      font-size: 19px;
      line-height: 30px;
    }
  }
  .right {
    width: 300px;
    .not_src {
      + .select2 {
        .select2-selection {
          height: 65px;
          background: #bbb;
          border: none;
        }
        .select2-selection__rendered {
          font-size: 13px;
          color: #fff;
          font-weight: 700;
          text-transform: uppercase;
          padding: 17px 70px 17px 30px;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .select2-selection__arrow {
          background: #a0a0a0;
          width: 65px;
          height: 63px;
          b {
            width: 26px;
            height: 14px;
            content: "";
            background: url("../images/material/arr_sub.png") no-repeat;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            @include transition(all ease-out 0.25s);
            border: none;
          }
        }
        &.select2-container--open {
          .select2-selection__arrow {
            b {
              @include transition(all ease-out 0.25s);
              @include transform(rotate(-180deg));
            }
          }
        }
      }
    }
    /*select {
            width: 100%; @include boxSizing(border-box); font-size: 13px; font-weight: 600; background: #bbbbbb; color: #fff;
            padding: 20px 70px 20px 30px; text-transform: uppercase; position: relative;
            &:after {
                content:'..'; display: block; position: absolute; color: #fff;
            }
        }*/
  }
}
.select2-search {
  display: none;
}
.select2-results__option {
  padding: 10px 30px;
  font-size: 13px;
  text-transform: uppercase;
}
.gridNews {
  width: 970px;
  margin: 0 auto;
  .box {
    display: block;
    position: relative;
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 1px solid #ccc;
    .img {
      margin-bottom: 40px;
    }
    h3 {
      font-size: 33px;
      line-height: 45px;
      color: $c_333;
      font-weight: 700;
      width: 80%;
      margin-bottom: 30px;
      @include transition(all ease-out 0.25s);
      &:hover {
        color: $c_ckb2;
        @include transition(all ease-out 0.25s);
      }
    }
    .shortdec {
      position: relative;
      padding-left: 170px;
      &:after {
        width: 16px;
        height: 16px;
        border: 2px solid $c_ckb;
        display: block;
        content: "";
        position: absolute;
        left: 105px;
        right: auto;
        top: -2px;
        @include borderRadius(50%);
      }
      &:before {
        width: 100px;
        height: 1px;
        background: $c_999;
        position: absolute;
        left: 0;
        right: auto;
        top: 7px;
        content: "";
        position: absolute;
      }
      .categ {
        @extend .after_clear;
        margin-bottom: 25px;
        label {
          float: left;
          font-size: 16px;
          color: $c_999;
        }
        nav {
          display: block;
          margin-left: 100px;
          a {
            display: inline-block;
            color: #f3c53d;
            font-size: 16px;
            &:hover {
              color: $c_ckb2;
            }
          }
        }
      }
      .dt {
        font-size: 16px;
        color: $c_999;
        margin-bottom: 20px;
        display: block;
      }
      p {
      }
    }
    &:last-of-type {
      border: none;
      padding-bottom: 0;
    }
  }
}

.pg_career {
  overflow: hidden;
  padding: 165px 0 30px;
  position: relative;
  height: 845px;
  @include boxSizing(border-box);
  .career_top {
    position: relative;
    @extend .after_clear;
  }
  .img_carrer {
    float: right;
  }
  .text_career {
    position: absolute;
    top: 40%;
    @include transform(translateY(-50%));
    width: 660px;
    left: 160px;
    h3 {
      font-size: 48px;
      font-weight: 800;
      color: $c_ckb;
      margin-bottom: 30px;
      line-height: 55px;
    }
  }
}

.slideTestimoni {
  width: 1030px;
  margin: 0 auto 100px;
  @extend .after_clear;
  .box_img {
    width: 330px;
    float: left;
    display: block;
    position: relative;
    z-index: 2;
  }
  .box_testimoni {
    width: 700px;
    float: right;
    position: relative;
    display: block;
    background: #fff;
    @include boxShadow(0 0 25px 0px rgba(0, 0, 0, 0.1));
    .textTestimoni {
      position: relative;
      z-index: 3;
      background: #fff;
    }
    .text {
      h2 {
        padding: 20px 35px;
        color: $c_333;
        font-size: 28px;
        border-bottom: 1px solid #ccc;
        font-weight: 700;
        position: relative;
      }
    }
    .isi {
      padding: 85px 120px 60px;
      position: relative;
      &:before {
        content: "";
        width: 83px;
        height: 60px;
        position: absolute;
        display: block;
        background: url("../images/material/kutip.png") no-repeat;
        top: 50px;
        left: 40px;
      }
      p {
        font-size: 19px;
        line-height: 30px;
        font-style: italic;
        position: relative;
      }
      .ket {
        margin-top: 40px;
        position: relative;
        span {
          display: inline-block;
          padding-right: 20px;
          font-size: 16px;
          line-height: 24px;
          color: #777;
          font-weight: 500;
          &:nth-of-type(1) {
            margin-right: 20px;
            border-right: 1px solid #ccc;
          }
        }
      }
    }
    &:after {
      content: "";
      background: $c_ckb;
      width: 100%;
      position: absolute;
      display: block;
      height: 100%;
      top: 50px;
      left: -50px;
      z-index: 1;
    }
  }
  .textTestimoni {
    .slick-arrow {
      position: absolute;
      z-index: 4;
      top: 0;
      right: 0;
      width: 70px;
      height: 69px;
      background: $c_ckb;
      border: none;
      font-size: 0;
      cursor: pointer;
      &:after {
        width: 18px;
        height: 12px;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: url("../images/material/arr_pagging.png") no-repeat left
          center;
        @include transition(all ease-out 0.25s);
      }
      &:hover {
        &:after {
          @include transition(all ease-out 0.25s);
          background-position: right center !important;
        }
      }
      &.slick-prev {
        right: 70px;
        &:before {
          height: 60%;
          width: 1px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          display: block;
          background: #fff;
          content: "";
        }
      }
      &.slick-next {
        &:after {
          @include transform(rotate(-180deg));
        }
      }
    }
  }
}

.sec_contact {
  position: relative;
}
#id_contactMap {
  height: 880px;
}
.map_ket {
  position: absolute;
  padding: 85px;
  @include boxSizing(border-box);
  width: 685px;
  @include boxShadow(0 0 20px -5px rgba(0, 0, 0, 0.5));
  top: 50%;
  left: 5%;
  @include transform(translateY(-50%));
  background: #fff;
  .bx_btn {
    position: absolute;
    bottom: -45px;
    left: 85px;
    right: 85px;
    a {
      line-height: 90px;
      padding: 0 20px;
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      display: inline-block;
      width: 175px;
      &.ct1 {
        background: #278162 url("../images/material/ico_btn_ijo.png") no-repeat
          95% center;
        margin-right: 10px;
      }
      &.ct2 {
        background: #f4cb50 url("../images/material/ico_btn_ylow.png") no-repeat
          95% center;
      }
    }
  }
  .ic_box {
    padding-bottom: 15px;
    margin-bottom: 30px;
    position: relative;
    &:before {
      content: "";
      width: 200px;
      background: $c_ckb;
      height: 2px;
      position: absolute;
      display: block;
      left: 0;
      bottom: 0;
    }
  }
  h6 {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  p {
    font-size: 19px;
    line-height: 30px;
  }
}
.btm_contact {
  @extend .after_clear;
  overflow: hidden;
  position: relative;
  margin-bottom: -100px;
  .lf_contact {
    float: left;
    width: 50%;
    display: block;
    background: url("../images/content/bg_lf_contact.jpg") no-repeat;
    height: 450px;
    background-size: cover;
    position: relative;
    .text_contact {
      left: 270px;
      .ic_box {
        color: #fff;
      }
      p {
        color: #fff;
      }
      .continue {
        color: #f4cb50;
        span {
          background: #f4cb50;
        }
      }
    }
  }
  .rh_contact {
    float: right;
    width: 50%;
    display: block;
    background: url("../images/content/bg_rh_contact.jpg") no-repeat;
    height: 450px;
    background-size: cover;
    position: relative;
    .img {
      right: 180px;
    }
  }
  .img {
    position: absolute;
    right: 10px;
    bottom: -5px;
  }
  .text_contact {
    position: absolute;
    float: left;
    top: 50%;
    @include transform(translateY(-50%));
    width: 350px;
    left: 75px;
    .ic_box {
      font-size: 28px;
      &:after {
        background-position: top center;
      }
    }
    .continue {
      font-size: 13px;
      line-height: 13px;
      margin-top: 15px;
      span {
        width: 13px;
        height: 13px;
        background-size: 100%;
        vertical-align: top;
      }
    }
  }
}

.banner_map {
  position: relative;
}
#map_office {
  height: 880px;
}

.listnetwork {
  .item {
    width: 30.6%;
    margin-right: 4.1%;
    @include transition(box-shadow 0.2s ease-out);
    margin-bottom: 50px;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    .keterangan {
      padding: 25px 20px;
      background: #fff;
      .loc {
        @extend .after_clear;
        margin-bottom: 20px;
        img {
          float: left;
          width: 100px;
        }
        span {
          margin-left: 120px;
          display: block;
          padding-left: 20px;
          line-height: 20px;
          font-size: 16px;
          font-weight: 600;
          position: relative;
          padding-top: 5px;
          &:before {
            content: "";
            width: 1px;
            background: #ccc;
            height: 34px;
            left: 0;
            top: 0;
            bottom: 0;
            position: absolute;
          }
        }
      }
      p:last-of-type {
        margin-bottom: 0;
      }
    }
    &:hover {
      @include boxShadow(0 0 40px 0 rgba(0, 0, 0, 0.2));
      @include transition(box-shadow 0.2s ease-out);
    }
  }
}
.contact_office {
  hr {
    border: none;
    display: block;
    height: 2px;
    margin: 10px 0 40px;
    background: #ccc;
  }
}

.section_bottom {
  position: relative;
  @extend .after_clear;
  border-top: 1px solid #ccc;
  .topTop {
    float: left;
    width: 70px;
    height: 70px;
    text-align: center;
    cursor: pointer;
    background: $c_ckb;
    .icon {
      width: 25px;
      height: 24px;
      background: url("../images/material/scroll_top.png") no-repeat top center;
      display: inline-block;
      margin: 10px 0 12px;
      @include transition(all ease-out 0.25s);
    }
    h6 {
      font-size: 13px;
      text-transform: uppercase;
      color: #fff;
    }
    &:hover {
      .icon {
        @include transition(all ease-out 0.25s);
        background-position: bottom center !important;
      }
      h6 {
        @include transition(all ease-out 0.25s);
        color: $c_yllow;
      }
    }
  }
  .rh {
    float: right;
  }
  .pagging {
    text-align: center;
    display: block;
    padding-top: 15px;
    a {
      width: 27px;
      height: 35px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      line-height: 35px;
      font-size: 19px;
      color: #333;
      font-weight: 500;
      padding: 0 8px 0 0;
      &.prev,
      &.next {
        background: $c_ckb;
        &:after {
          content: "";
          width: 18px;
          height: 12px;
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          top: 0;
          margin: auto;
          background: url("../images/material/arr_pagging.png") no-repeat left
            center;
          @include transition(all linear 0.2s);
        }
        &:hover {
          background: $c_ckb;
          &:after {
            @include transition(all linear 0.2s);
            background-position: right center;
          }
        }
        &.next {
          &:after {
            @include transform(rotate(-180deg));
          }
        }
      }
      &:hover,
      &.active {
        background: $c_yllow;
      }
    }
    span {
      display: inline-block;
      margin: 0 2px 0 8px;
      font-size: 19px;
      font-weight: 500;
      &:nth-of-type(2) {
        margin-left: 10px;
        margin-right: 8px;
      }
    }
    &.rh {
      a {
        text-align: right;
      }
    }
  }
  .btn_bk {
    line-height: 50px;
    border: 1px solid #ccc;
    font-size: 16px;
    padding: 0 15px 0 50px;
    color: #ccc;
    position: relative;
    @include transition(all linear 0.2s);
    font-weight: 500;
    &:after {
      content: "";
      position: absolute;
      width: 23px;
      height: 12px;
      background: url("../images/material/arr_bk.png") no-repeat left center;
      left: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
      @include transition(all linear 0.2s);
    }
    &:hover {
      color: $c_ckb;
      @include transition(all linear 0.2s);
      &:after {
        @include transition(all linear 0.2s);
        background-position: right center;
      }
    }
  }
  &.nobor {
    border: none;
    margin: 50px 0 -50px;
  }
}

.map_info {
  @extend .after_clear;
  width: 450px;
  padding: 0 20px 20px;
  background: #fff;
  @include boxSizing(border-box !important);
  h1 {
    font-size: 19px;
    color: #333;
    background: $c_yllow;
    padding: 10px 20px;
    margin-bottom: 15px;
    margin: 0 -20px 20px;
  }
  .content_map {
    @extend .after_clear;
    .left {
      p {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 20px;
        &.tlp {
          margin-bottom: 0;
        }
      }
    }
  }
}
.map_content {
  background: #fff;
  padding: 0 20px 20px;
  width: 300px;
  @include boxSizing(border-box);
  .dec_map {
    h4 {
      font-size: 19px;
      background: $c_yllow;
      padding: 10px 20px;
      color: #333;
      font-weight: 600;
      margin: 0 -20px 20px;
    }
  }
}
#map_office {
  .gm-style .gm-style-iw {
    width: 300px !important;
    top: 18px !important;
  }
}
.gm-style .gm-style-iw {
  width: 450px !important;
  @include boxSizing(border-box !important);
  top: 16px !important;
  left: 0 !important;
  border-radius: 2px 2px 0 0;
  @include borderRadius(15px);
  + div {
    right: 70px !important;
    top: 30px !important;
    width: 15px !important;
    height: 15px !important;
    background: url("../images/material/ico_close_map.png") no-repeat;
    background-size: 100% !important;
    opacity: 1 !important;
    img {
      display: none;
    }
    + img {
      top: 16px !important;
      left: auto !important;
      right: 60px !important;
    }
  }
}

.inquiry {
  .pg_news {
    > .ic_box {
      color: #fff;
      &:after {
        background-position: top center;
      }
      &:before {
        background: #fff;
      }
    }
  }
  .txt_filter {
    .left {
      p {
        color: #fff;
      }
    }
    .right {
      .not_src {
        + .select2 {
          .select2-selection {
            background: #fff;
            height: 62px;
          }
          .select2-selection__rendered {
            color: $c_ckb;
            border-left: 5px solid $c_yllow;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .select2-selection__arrow {
            background: #13523c;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }
}

.box_form {
  .row {
    @extend .after_clear;
    margin-bottom: 50px;
    > h3 {
      font-size: 22px;
      color: #fff;
      margin-bottom: 35px;
      display: block;
      text-transform: uppercase;
      font-weight: 600;
    }
    > label {
      font-size: 16px;
      margin-bottom: 35px;
      color: #79f5ca;
      display: block;
      i {
        color: #fa5763;
      }
      u {
        text-transform: uppercase;
        font-weight: 500;
        text-decoration: none;
      }
    }
    > p {
      font-size: 16px;
      line-height: 24px;
      color: #fff;
    }
    .col {
      float: left;
      width: 46%;
      margin-right: 8%;
      &:nth-of-type(2) {
        margin-right: 0;
      }
      input[type="text"],
      input[type="email"],
      input[type="number"],
      select,
      textarea,
      input[type="password"] {
        width: 100%;
        @include boxSizing(border-box);
        background: transparent;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        padding: 10px 0;
      }

      textarea {
        resize: none;
      }
      select {
        background: url("../images/material/arr_select.png") no-repeat 95%
          center;
        background-size: 18px;
        padding-right: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        option {
          background: $c_yllow;
          border: none;
          padding: 10px 20px;
          color: $c_ckb;
        }
      }
      .field {
        position: relative;
        .anim-label {
          position: relative;
          z-index: 3;
          @include transition(all ease-out 0.2s);
        }
        .date_pick {
          background: url("../images/material/ico_date_form.png") no-repeat 95%
            center;
          padding-right: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          + .lbel {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
          }
        }
        .lbel {
          position: absolute;
          display: block;
          font-size: 18px;
          color: #fff;
          top: 12px;
          left: 0px;
          right: 0;
          z-index: 2;
          padding-right: 40px;
          i {
            color: #fa5763;
          }
        }
        .gender {
          width: 200px;
        }
        &.focus {
          .anim-label {
            @include transition(border-color ease-out 0.2s);
            border-color: #79f5ca;
          }
          .date_pick {
            background: url("../images/material/ico_date_form2.png") no-repeat
              95% center;
          }
          .lbel {
            font-size: 16px;
            color: #79f5ca;
            @include transition(all ease-out 0.2s);
          }
        }
        &.attach {
          display: none;
          .notes {
            font-size: 12px;
            display: block;
            margin-top: 10px;
            color: #fff;
            font-style: italic;
            display: block;
            line-height: 14px;
          }
        }
        &.ck_short {
          > label {
            font-size: 16px;
            color: #79f5ca;
            margin: -15px 0 20px;
            display: block;
            i {
              color: #fa5763;
            }
          }
          .ck_radio {
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 0;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
      /*label {
                font-size: 23px; color: #fff; margin-bottom: 20px; display: block;
                + .ck_radio {
                    display: inline-block; margin-right: 20px;
                    + .ck_radio {
                        display: inline-block;
                    }
                }
            }*/
      .ck_radio,
      .ck_checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        font-size: 18px;
        color: #fff;
        margin-bottom: 20px;
        line-height: 23px;
        cursor: pointer;
        input[type="radio"],
        input[type="checkbox"] {
          width: 16px;
          height: 16px;
          position: absolute;
          left: 0;
          right: 0;
          top: 4px;
          background: url("../images/material/ck_radio.png") no-repeat top
            center;
          padding: 0;
          cursor: pointer;
          &:checked {
            background-position: bottom center;
          }
        }
      }
      .ck_checkbox {
        input[type="checkbox"] {
          width: 20px;
          height: 21px;
          background: url("../images/material/ico_check.png") no-repeat top
            center;
        }
        @include boxSizing(border-box);
        &:nth-of-type(2n) {
          margin-right: 0;
        }
        .other {
          display: none;
        }
      }
      &.full {
        width: 100%;
        margin-right: 0;
      }
      .input_file {
        width: 100% !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        height: 46px !important;
        background: url("../images/material/ico_file.png") no-repeat 95% center;
        background-size: 22px;
        input {
          width: 100% !important;
          display: block;
          left: 0;
          right: 0;
          padding: 12px 0;
          z-index: 2;
        }
        span {
          font-size: 18px;
          color: #fff;
          display: block;
          top: 10px;
          position: relative;
          z-index: 1;
          padding-right: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 20px;
        }
        &.ok_file {
          background: url("../images/material/ico_file2.png") no-repeat 95%
            center;
        }
      }
      button {
        padding: 0;
      }
      .btn_box {
        cursor: pointer;
        &.list_arr {
          border-color: $c_yllow;
          span {
            color: $c_yllow;
            padding: 0 40px;
            line-height: 56px;
          }
          &:after {
            padding: 0 40px;
            color: $c_ckb;
            line-height: 56px;
          }
          &:before {
            display: none;
          }
          &:hover {
            background: $c_yllow;
          }
        }
      }
      &.unit_sat {
        > label {
          display: block;
          font-size: 16px;
          color: #79f5ca;
          margin-bottom: 30px;
          text-transform: uppercase;
          font-weight: 500;
          i {
            color: #fa5763;
          }
        }
        .field {
          width: 40%;
          display: inline-block;
          vertical-align: top;
          &:nth-of-type(2) {
            width: 50%;
            margin-left: 8%;
          }
        }
      }
    }
    &.change_rd {
      margin-bottom: 35px;
    }
    &.col-3_sat {
      margin-bottom: 0;
      .col {
        width: 30%;
        margin-right: 5%;
        &:nth-of-type(3) {
          margin-right: 0;
        }
      }
    }
  }
  .unit_satuan {
    padding: 40px 20px 25px;
    background: rgba(255, 255, 255, 0.075);
    margin: 0 -20px 40px;
  }
  .add_field {
    &.hide {
      display: none;
    }
  }
  .add {
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url("../images/material/ico_add.png") no-repeat top left;
    font-size: 0;
    &:hover {
      background-position: right top;
    }
  }
  .remove {
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url("../images/material/ico_add.png") no-repeat bottom left;
    font-size: 0;
    &:hover {
      background-position: right bottom;
    }
  }
  .add,
  .remove {
  }
}

.top_result {
  padding-top: 70px;
  @extend .after_clear;
  h3 {
    font-size: 30px;
    color: $c_ckb;
    font-weight: 600;
    float: left;
  }
  .src_result {
    float: right;
    form {
      position: relative;
      input[type="text"] {
        border-color: $c_ea;
        font-size: 14px;
        width: 250px;
        padding-right: 30px;
        @include boxSizing(border-box);
      }
      input[type="submit"] {
        position: absolute;
        width: 20px;
        height: 20px;
        background: url("../images/material/ico_src_head.png") no-repeat center;
        border: none;
        top: 0;
        bottom: 0;
        right: 10px;
        margin: auto;
        cursor: pointer;
      }
    }
  }
  .notes {
    margin-top: 30px;
    font-size: 16px;
  }
}
.list_result {
  min-height: 15vh;
  .list {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #ccc;
    h3 {
      font-size: 22px;
      color: $c_666;
      margin-bottom: 15px;
      font-weight: 500;
      line-height: 28px;
    }
    p {
      margin-bottom: 15px;
      font-size: 16px;
    }
    a.continue {
      font-size: 14px;
      span {
        width: 14px;
        height: 14px;
        background-size: 100%;
      }
    }
    a:hover {
      h3 {
        color: #129b6c;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

.full-section {
  position: relative;
  figcaption {
    position: absolute;
    top: 50%;
    left: 5%;
    height: 54px;
    width: 680px;
    margin: 0;
    z-index: 3;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-top: 50px;
    .button-group {
      a {
        width: 216px;
        height: 54px;
        line-height: 54px;
        background: url('../images/material/bg-link.png') no-repeat 0 0;
        display: inline-flex;
        align-items: center;
        justify-content: center; 
        color:#fff; 
        text-transform: capitalize;
        font-size: 15px;
        margin: 0 16px;
      }
    }
  }
  figure {
    img {
      width: 100%;
      height: 100svh;
      object-fit: cover;
      object-position: left center; 
    }
  }
}

.box-wa {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 9000;
  a {
    display: inline-block;
    border: 1px solid #2ad265;
    background: #2ad265;
    height: 50px;
    padding: 0 30px;
    line-height: 50px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -o-transition: 0.2s all;    
    padding: 0;
    background: none !important;
    border: none;
    i {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      top: -3px;      
      background-repeat: no-repeat;
      background-position: top center;
      display: inline-block;
      position: relative;  
      background-image: url("../images/material/ic-wa.png");
      width: 30px;
      height: 30px;
      transition: 0.2s all;
      -webkit-transition: 0.2s all;
      -moz-transition: 0.2s all;
      -ms-transition: 0.2s all;
      -o-transition: 0.2s all;
    }
    &:hover {
      border-color: #2ad265;
      background: #fff;
      color: #2ad265;
      i {
        background-position: bottom center !important;
      }
    }
  }  
  &.absSroll {
    animation-name: bounching;
    animation-duration: .3s;
    bottom: 100px;
  }
}
@keyframes bounching {
  0% {
    bottom: 95px;
  }
  35% {
    bottom: 100px;
  }
  65% {
    bottom: 95px;
  }
  100% {
    bottom: 100px;
  }
}


.ui-datepicker {
  width: 250px;
  border: none;
  background: #fff;
  padding: 0;
  @include borderRadius(10px);
  .ui-widget-header {
    background: $c_yllow;
    padding: 6px 0;
    margin-bottom: 10px;
    @include borderRadius(10px 10px 0 0);
    .ui-datepicker-title {
      font-size: 16px;
    }
    .ui-datepicker-next {
      top: 9px;
      right: 10px;
    }
    .ui-datepicker-prev {
      top: 9px;
      left: 10px;
    }
  }

  .ui-datepicker-calendar {
    .ui-state-default {
      border: none;
      background: #fff;
      font-size: 15px;
      padding: 10px 5px;
      text-align: center;
      &:hover {
        background: $c_ckb;
        @include borderRadius(17px);
        color: #fff;
      }
      &.ui-state-active {
        background: $c_ckb;
        @include borderRadius(17px);
      }
    }
  }
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  background: $c_yllow url("../images/material/arr_date.png") no-repeat 95%
    center;
  color: #333;
  font-weight: 700;
  border: none;
}
.ui-datepicker select.ui-datepicker-month {
  margin-right: 6px;
  display: inline-block;
}



/* footer
----------------------------------------------------------------------------------------------*/
footer {
  h4 {
    font-size: 19px;
    color: #23926c;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 15px;
    a {
      font-size: 19px;
      color: #23926c;
      font-weight: 800;
      text-transform: uppercase;
    }
  }
  .top_footer {
    background: #0b0e0e;
    @extend .after_clear;
    padding: 70px 0 50px;
    .wrapper {
      width: 1600px;
    }
    .foot_addrs {
      @extend .after_clear;
      .lgo {
        float: left;
      }
      .txt {
        display: block;
        margin-left: 215px;
        color: $c_666;
        p {
          font-size: 13px;
          line-height: 20px;
          margin-bottom: 2px;
          a {
            color: $c_666;
          }
        }
        .sos {
          margin-top: 20px;
          .ic_sos {
            font-size: 13px;
            color: $c_666;
            display: block;
            @include transition(color ease-out 0.25s);
            margin-bottom: 15px;
            span {
              display: inline-block;
              width: 20px;
              height: 20px;
              vertical-align: middle;
              margin-right: 10px;
            }
            &:hover {
              @include transition(color ease-out 0.25s);
              color: #fff;
              span {
                background-position: bottom center !important;
              }
            }
          }
        }
      }
    }
    .main_foot {
      @extend .after_clear;
      .col {
        float: left;
        width: 230px;
        margin-right: 30px;
        ul {
          li {
            margin-bottom: 10px;
            a {
              font-size: 13px;
              color: $c_666;
              line-height: 20px;
              @include transition(color ease-out 0.25s);
              &:hover {
                @include transition(color ease-out 0.25s);
                color: #fff;
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  .btm_footer {
    height: auto;
    min-height: 85px;
    padding: 48px 0;
    @include boxSizing(border-box);
    .wrapper {
      width: 1600px;
      position: relative;
    }

    .left {
      width: 50%;
      /*ul { @include afterboth; list-style: none; font-size: 12px; margin-bottom: 10px; color: $c_ckb2; margin: 0; font-weight: 800; text-transform: uppercase; display: block;
                li { display: inline-block; position: relative; margin-right: 15px; 
                    &:first-child:before { content:""; }
                    &:before { position: absolute; content: "|"; left: 0; }
                    &+li { padding-left: 18px; }
                }
            }*/
      a {
        color: $c_ckb2;
        display: inline-block;
        &:hover {
          color: $c_ckb;
        }
      }
      p {
        font-size: 16px;
        color: #0e6151;
        line-height: normal;
        margin: 0;
        font-weight: 500;
        margin-top: 10px;
        line-height: 160%;
        a {
          color: #0e6151;
          text-decoration: underline;
        }
        &.desc {
          font-size: 15px;
          color: #808080;
        }
      }
    }
    .right {
      padding-top: 32px;
      .list_foot {
        a {
          display: inline-block;
          margin: 0 3px;
        }
      }
    }
    .left,
    .right {
      float: none;
      width: 100%;
      display: block;
      position: relative;
      text-align: center;
      top: 0;
      transform: translateY(0);
    }
  }
}
